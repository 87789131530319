import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { useWindowWidth } from '../../hooks/window-width'
import { IBlogPostInformation } from '../../models/blog-post/types'
import { linkGenerator, PrismicDocumentType } from '../../utils/link-generator'
import Pagination from '../common/pagination'

interface IProps {
  data: IBlogPostInformation[]
  toolbar?: boolean
}

export default function Mosaic({ data, toolbar }: IProps) {
  const windowWidth = useWindowWidth()
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const [itemCountPerPage] = useState(windowWidth > 480 ? 9 : 5)
  const pageCount = Math.ceil(data.length / itemCountPerPage)

  return (
    <Container>
      <MosaicContainer>
        {data &&
          data.map(
            (elem, index) =>
              index >= currentPageIndex * itemCountPerPage &&
              index < (currentPageIndex + 1) * itemCountPerPage && (
                <BlogPostContainer
                  background={elem.thumbnailImage.url}
                  key={index}
                  to={linkGenerator(PrismicDocumentType.BLOG_POST, elem.uid, elem.parent.uid)}
                >
                  <div />
                  <p>
                    <span>{elem.date}</span>
                    <span>•</span>
                    <span>{elem.categories[0]}</span>
                  </p>
                  <h6>{elem.title}</h6>
                </BlogPostContainer>
              )
          )}
      </MosaicContainer>
      {toolbar && pageCount > 1 && (
        <Pagination
          currentPageIndex={currentPageIndex}
          pageCount={pageCount}
          pagesButtonCount={5}
          setCurrentPageIndex={setCurrentPageIndex}
          type="blog-post"
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`

const MosaicContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;

  & > div:nth-child(3n + 2) {
    margin-left: 24px;

    @media screen and (max-width: 480px) {
      margin-left: 0;
    }
  }

  & > div:nth-child(3n) {
    margin-left: 24px;

    @media screen and (max-width: 480px) {
      margin-left: 0;
    }
  }
`

const BlogPostContainer = styled(Link)<{ background: string }>`
  align-items: flex-start;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 320px;
  justify-content: flex-start;
  margin: 16px 0;
  padding: 16px;
  transition: all 200ms ease-in;
  width: calc((100% - 48px) / 4);

  @media screen and (max-width: 480px) {
    padding: 0;
    height: unset;
    width: 100%;
  }

  :hover {
    box-shadow: 0 6px 24px 0 rgba(74, 74, 74, 0.2);
    transform: scale(1.02);
  }

  & > div {
    background: ${({ background }) => `url("${background}") center center`};
    background-size: cover;
    border-radius: 4px;
    height: 192px;
    width: 100%;
  }

  & > p {
    font-size: 14px;
    line-height: 14px;
    opacity: 0.5;
    margin: 14px 0 0 0;
    text-transform: uppercase;

    & > span:nth-child(2) {
      margin: 0 4px;
    }
  }

  & > h6 {
    /* stylelint-disable */
    color: ${({ theme }) => theme.palette.primary.main};
    display: -webkit-box;
    font-family: 'Proxima Nova ExtraBold', sans-serif;
    font-size: 20px;
    margin: 8px 0 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
`
