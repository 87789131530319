import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import SvgArrowRight from '../svg/arrow-right'
import { IBlogPostInformation } from '../../models/blog-post/types'
import { linkGenerator, PrismicDocumentType } from '../../utils/link-generator'
import Pagination from '../common/pagination'

interface IProps {
  data: IBlogPostInformation[]
  toolbar?: boolean
}

export default function List({ data, toolbar }: IProps) {
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const [itemCountPerPage] = useState(9)
  const pageCount = Math.ceil(data.length / itemCountPerPage)

  return (
    <Container>
      <ListContainer>
        {data &&
          data.map(
            (elem, index) =>
              index >= currentPageIndex * itemCountPerPage &&
              index < (currentPageIndex + 1) * itemCountPerPage && (
                <BlogPostContainer
                  background={elem.thumbnailImage.url}
                  key={index}
                  to={linkGenerator(PrismicDocumentType.BLOG_POST, elem.uid, elem.parent.uid)}
                >
                  <div />
                  <div>
                    <div>
                      <p>
                        <span>{elem.date}</span>
                        <span>•</span>
                        <span>{elem.categories[0]}</span>
                      </p>
                      <h6>{elem.title}</h6>
                    </div>
                    <div>
                      <SvgArrowRight />
                    </div>
                  </div>
                </BlogPostContainer>
              )
          )}
      </ListContainer>
      {toolbar && pageCount > 1 && (
        <Pagination
          currentPageIndex={currentPageIndex}
          pageCount={pageCount}
          pagesButtonCount={5}
          setCurrentPageIndex={setCurrentPageIndex}
          type="blog-post"
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`

const ListContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: colmn;
  flex-wrap: wrap;
  justify-content: center;
  margin: 32px 0;
  width: 100%;
`

const BlogPostContainer = styled(Link)<{ background: string }>`
  align-items: center;
  border-bottom: ${({ theme }) => `solid 1px ${theme.palette.info.main}`};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 128px;
  justify-content: flex-start;
  padding: 24px;
  transition: all 200ms ease-in;
  width: 100%;

  & > div:nth-child(1) {
    background: ${({ background }) => `url("${background}") center center`};
    background-size: cover;
    border-radius: 4px;
    height: 100%;
    width: 112px;
  }

  & > div:nth-child(2) {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: space-between;
    width: calc(100% - 112px);

    & > div {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      padding-left: 24px;

      & > p {
        font-size: 14px;
        opacity: 0.5;
        margin: 0;
        text-transform: uppercase;

        & > span:nth-child(2) {
          margin: 0 4px;
        }
      }

      & > h6 {
        color: ${({ theme }) => theme.palette.primary.main};
        font-family: 'Proxima Nova ExtraBold', sans-serif;
        font-size: 20px;
        margin: 0;
      }

      & > svg {
        height: 24px;
        opacity: 0;
        width: 24px;

        & > path {
          fill: ${({ theme }) => theme.palette.secondary.main};
        }
      }
    }
  }

  :hover {
    border-radius: 4px;
    border-bottom: none;
    box-shadow: 0 6px 24px 0 rgba(74, 74, 74, 0.2);
    transform: scale(1.02);

    & > div > div > svg {
      opacity: 1;
    }
  }
`
