import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import slugify from 'slugify'

import { getIsBrowser } from '../../utils/is-browser'
import { useWindowWidth } from '../../hooks/window-width'
import { IBlogPostInformation } from '../../models/blog-post/types'
import { DisplayMode } from '../../types/custom'

import List from './list'
import Mosaic from './mosaic'

interface IProps {
  data: IBlogPostInformation[]
  defaultDisplayMode?: DisplayMode
  toolbar?: boolean
  withFilters?: boolean
}

export default function BlogPostLibrary({ data, toolbar, withFilters }: IProps) {
  const isBrowser = getIsBrowser()
  const windowWidth = useWindowWidth()
  const [isInitialized, setIsInitialized] = useState(false)
  const [fileredCategories, setFilteredCategories] = useState<string[]>([])
  const categories = getCategories()

  useEffect(() => {
    const url = isBrowser ? new URL(window.location.href) : null
    const params = url ? new URLSearchParams(url.search) : null
    let tagsArray: string[] = []
    if (params) {
      const tags = params.get('tags')
      if (tags) {
        tagsArray = tags.split(',')
        tagsArray = tagsArray.map((value) => {
          for (const category of categories) {
            const slugCategory = slugify(category.label, {
              replacement: '-',
              remove: /[*+~.()'"!:@/?\\]/gu,
              lower: true,
            })
            if (slugCategory === value) return category.label
          }

          return value
        })
      }
    }
    setFilteredCategories(isInitialized ? fileredCategories : tagsArray)
    setIsInitialized(true)
  }, [categories, isInitialized, fileredCategories, isBrowser])

  function getCategories() {
    // TODO: fix accents problems with 'Évènements'
    const res: Array<{ label: string; count: number }> = []

    for (const { categories: cats } of data) {
      for (const category of cats) {
        const index = res.findIndex((elem) => {
          if (category === 'Évènements' || category === 'Événements') {
            return elem.label === 'Évènements'
          }

          return elem.label === category
        })

        if (index !== -1) {
          res[index].count += 1
        } else {
          res.push({
            label: category === 'Évènements' || category === 'Événements' ? 'Évènements' : category,
            count: 1,
          })
        }
      }
    }

    return res.sort((a, b) =>
      a.label.normalize('NFD').replace(/[\u0300-\u036F]/gu, '') <
      b.label.normalize('NFD').replace(/[\u0300-\u036F]/gu, '')
        ? -1
        : 1
    )
  }

  function handleClickCategory(category: string) {
    const index = fileredCategories.findIndex((fileredCategory) => fileredCategory === category)
    const tmp = fileredCategories.slice()

    if (index === -1) {
      tmp.push(category)
    } else {
      tmp.splice(index, 1)
    }

    setFilteredCategories(tmp)
    updateURL(tmp)
  }

  function updateURL(tmp: string[]) {
    const url = isBrowser ? new URL(window.location.href) : null
    const params = url ? new URLSearchParams(url.search) : null

    if (params) {
      if (tmp) {
        const slugifiedTags = tmp.map((value) =>
          slugify(value, {
            replacement: '-',
            remove: /[*+~.()'"!:@/?\\]/gu,
            lower: true,
          })
        )
        params.set('tags', slugifiedTags.join(','))
        const string = `tags=${slugifiedTags.join(',')}`
        if (isBrowser) {
          window.history.replaceState({}, '', `${location.pathname}?${string}`)
        }
      }
    }
  }

  function isDisplayed(blogPost: IBlogPostInformation) {
    for (const category of blogPost.categories) {
      if (fileredCategories.includes(category)) {
        return true
      }
    }

    return false
  }

  return (
    <Container id="blog-post-library-container">
      <div>
        {toolbar && (
          <HeaderContainer>
            <FiltersContainer>
              {withFilters && (
                <>
                  <p>Filtrer par sujet</p>
                  <div>
                    {categories &&
                      categories.map((category, index) => (
                        <div key={index}>
                          <CategoryButton
                            onClick={() => handleClickCategory(category.label)}
                            selected={fileredCategories.includes(category.label)}
                          >
                            <span>{category.label}</span>
                            <div>
                              <CategoryCount large={category.count > 9}>{category.count}</CategoryCount>
                            </div>
                          </CategoryButton>
                          {index < categories.length - 1 && <span>/</span>}
                        </div>
                      ))}
                  </div>
                </>
              )}
            </FiltersContainer>
          </HeaderContainer>
        )}
        {windowWidth > 480 && (
          <>
            <Mosaic
              data={
                fileredCategories.length > 0
                  ? data.filter((elem) => isDisplayed(elem) && elem.primary)
                  : data.filter((elem) => elem.primary)
              }
              toolbar={toolbar}
            />
            <List
              data={
                fileredCategories.length > 0
                  ? data.filter((elem) => isDisplayed(elem) && !elem.primary)
                  : data.filter((elem) => !elem.primary)
              }
              toolbar={toolbar}
            />
          </>
        )}
        {windowWidth <= 480 && (
          <Mosaic
            data={fileredCategories.length > 0 ? data.filter((elem) => isDisplayed(elem)) : data}
            toolbar={toolbar}
          />
        )}
      </div>
    </Container>
  )
}

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 48px;
  width: 100%;

  & > div {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    @media screen and (max-width: 480px) {
      width: 100%;
    }
  }
`

const HeaderContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 480px) {
    justify-content: flex-start;
  }
`

const FiltersContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: calc(100% - 80px);

  @media screen and (max-width: 480px) {
    margin-bottom: 24px;
    width: 100%;
  }

  & > p {
    font-size: 12px;
    opacity: 0.8;
    text-transform: uppercase;
  }

  & > div {
    align-items: center;
    display: flex;
    flex-direction: row;
    min-height: 24px;
    justify-content: flex-start;
    max-width: 100%;
    flex-wrap: wrap;

    & > div {
      align-items: center;
      display: flex;
      flex-direction: row;
      height: 100%;
      justify-content: flex-start;

      & > span {
        font-size: 16px;
        margin: 0 12px;

        @media screen and (max-width: 480px) {
          font-size: 14px;
        }
      }
    }
  }
`

const CategoryButton = styled.button<{ selected: boolean }>`
  align-items: center;
  display: flex;
  font-family: ${({ selected }) =>
    selected ? '"Proxima Nova Extrabold", sans-serif' : '"Proxima Nova Regular", sans-serif'};
  flex-direction: row;
  height: 100%;
  justify-content: flex-start;

  & > span {
    font-size: 16px;

    @media screen and (max-width: 480px) {
      font-size: 14px;
    }
  }

  & > div {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    margin-left: 2px;
    margin-top: -8px;
    width: 6px;
  }

  :hover {
    opacity: ${({ selected }) => (selected ? 1 : 0.7)};
  }
`

const CategoryCount = styled.div<{ large: boolean }>`
  font-size: 12px;
  margin-right: ${({ large }) => (large ? '-4px' : '0')};

  @media screen and (max-width: 480px) {
    font-size: 10px;
  }
`
