import React from 'react'
import styled from 'styled-components'

import SvgArrowLeft from '../../svg/arrow-left'
import SvgArrowRight from '../../svg/arrow-right'

interface IProps {
  currentPageIndex: number
  setCurrentPageIndex: (value: number) => void
  pageCount: number
  pagesButtonCount: number
  type: 'press' | 'blog-post'
}

export default function Pagination({
  currentPageIndex,
  setCurrentPageIndex,
  pageCount,
  pagesButtonCount,
  type,
}: IProps) {
  const pages = new Array(pagesButtonCount).fill(null)

  function transposeIndex(pageIndex: number): number {
    return currentPageIndex < 2 ? pageIndex : pageIndex + currentPageIndex - 2
  }

  function handleChangePage(pageIndex: number) {
    if (pageIndex !== currentPageIndex && window) {
      const anchor =
        type === 'press'
          ? window.document.querySelector('#outside-articles-container')
          : window.document.querySelector('#blog-post-library-container')

      if (anchor) {
        window.scrollTo({
          top: anchor.offsetTop - 64,
          left: 0,
        })
      } else {
        window.scrollTo(0, 0)
      }
    }
    setCurrentPageIndex(pageIndex)
  }

  return (
    <Container>
      <Button disabled={currentPageIndex <= 0} onClick={() => handleChangePage(0)}>
        Début
      </Button>
      <Button disabled={currentPageIndex <= 0} onClick={() => handleChangePage(currentPageIndex - 1)}>
        <SvgArrowLeft />
      </Button>
      {pages &&
        pages.map((elem, pageIndex) => {
          const index = transposeIndex(pageIndex)

          return (
            index < pageCount && (
              <Button current={index === currentPageIndex} key={index} onClick={() => handleChangePage(index)}>
                <span>{index + 1}</span>
              </Button>
            )
          )
        })}
      <Button disabled={currentPageIndex >= pageCount - 1} onClick={() => handleChangePage(currentPageIndex + 1)}>
        <SvgArrowRight />
      </Button>
      <Button disabled={currentPageIndex >= pageCount - 1} onClick={() => handleChangePage(pageCount - 1)}>
        Fin
      </Button>
    </Container>
  )
}

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const Button = styled.button<{ current?: boolean; disabled?: boolean }>`
  color: ${({ current, theme }) => (current ? theme.palette.secondary.main : theme.palette.text.primary)};
  font-size: 14px;
  height: 24px;
  margin: 0 4px;
  text-transform: uppercase;
  opacity: ${({ disabled }) => (disabled ? 0 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  @media screen and (max-width: 480px) {
    display: ${({ disabled }) => (disabled ? 'none' : 'initial')};
  }

  :hover {
    opacity: ${({ current }) => (current ? 1 : 0.7)};
  }

  & > svg {
    height: 24px;
    width: 24px;

    & > path {
      fill: ${({ theme }) => theme.palette.text.primary};
    }
  }
`
