import { useEffect, useState } from 'react'

import { getIsBrowser } from '../utils/is-browser'

export function useWindowWidth(): number {
  const isBrowser = getIsBrowser()
  const [width, setWidth] = useState(isBrowser ? window.innerWidth : 1366)

  useEffect(() => {
    if (!isBrowser) return

    window.addEventListener('load', handleEvent)
    window.addEventListener('resize', handleEvent)

    return (): void => {
      window.removeEventListener('load', handleEvent)
      window.removeEventListener('resize', handleEvent)
    }
  }, [])

  function handleEvent(): void {
    setWidth(window.innerWidth)
  }

  return width
}
